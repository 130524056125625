@use "../abstracts/variables" as *;
.greating__text {
  font-size: $body-text;
  margin: 10px 0;
}
.greating__heading {
  font-size: $headline;
  line-height: 85%;
}
.greating__text,
.greating__heading {
  color: $secondary-write;
}
.code__comment {
  color: $secondary-grey;
}
.code__variable {
  color: $secondary-write;
}
.text--green {
  color: $gradient-green;
}
.text--purple {
  color: $secondary-purple;
}
.text--orange {
  color: $accent-peach;
}
.code__variable a {
  border-bottom: 1px solid $accent-peach;
}
@media (min-width: 769px) {
  .home {
    z-index: 3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 70%;
    position: relative;
  }
  .greating {
    margin-bottom: 85px;
  }
  .greating__text {
    font-size: 32px;
  }
  .code {
    font-size: 18px;
  }
  .glow--effect {
    position: absolute;
    border-radius: 100%;
    background-color: #4d5bce;
    width: 350px;
    height: 350px;
    filter: blur(90px);
    z-index: 1;
    opacity: 0.4;
  }
  .glow--effect.green {
    background-color: #43d9ad;
  }
}

@media (max-width: 768px) {
  .home {
    padding: 0 28px;
  }
  .home__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 90vh;
  }
  .greating__heading {
    position: relative;
    right: 5px;
  }
  .code__comment {
    margin-bottom: 16px;
  }
  .glow--effect {
    width: 100%;
    height: calc(100% - 57px);
    position: absolute;
    background-image: url("../../assets/blurs.svg");
    background-repeat: no-repeat;
    filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    z-index: -1;
  }
}
