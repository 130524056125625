@use "../abstracts/variables" as *;
.contact__text {
  color: $secondary-grey;
}
@media (min-width: 769px) {
  .contact__confimation {
    padding: 42px 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: $border-size;
    text-align: center;
  }
  .contact__button {
    width: fit-content;
  }
  .contact__thankyou {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .contact__text {
    font-size: 18px;
    color: $secondary-grey;
  }
}
@media (max-width: 768px) {
  .contact__confimation {
    text-align: center;
    margin: 20px 0px;
  }
  .contact__thankyou {
    font-size: 24px;
    margin-bottom: 14px;
  }
  .contact__text {
    font-size: 18px;
    margin-bottom: 22px;
  }
}
