@use "../abstracts/variables" as *;
.project {
  align-self: center;
}
.project__name {
  margin-bottom: 16px;
  color: $secondary-blue;
  font-weight: 700;
}
.project__path {
  color: $secondary-grey;
}
.project__content {
  width: 291px;
  height: 328px;
  border: $border-size;
  background-color: $primary-blue;
  border-radius: 15px;
}
.project__info {
  padding: 29px 31px;
}
.project__img {
  max-width: 100%;
  height: 150px;
  background-image: url("https://images.unsplash.com/photo-1617791160530-fb48acfc1658?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px 15px 0px 0px;
}
.project__description {
  margin-bottom: 24px;
  width: 100%;
  color: $secondary-grey;
}
.project__link {
  background-color: $link;
  padding: 10px 14px;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .project__content {
    width: auto;
  }
  .projects__items {
    padding: 32px 0px;
  }
  .project {
    margin-bottom: 16px;
  }
}
