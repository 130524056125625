@use "../abstracts/variables" as *;
.nav {
  display: flex;
  height: 3.5rem;
  width: 100%;
  background-color: $primary-light;
  color: $secondary-grey;
  border-bottom: $border-size;
}
.nav ul {
  background-color: $primary-light;
}
.nav__button {
  flex-direction: column;
  justify-content: space-between;
  width: 18px;
  height: 16px;
}
.nav__button div {
  width: 18px;
  height: 2px;
  background-color: $secondary-grey;
  transform: rotate(0deg);
}
.nav__button {
  display: none;
}
.close--button div {
  position: relative;
}
.close--button div:nth-child(1) {
  transform: rotate(45deg);
  top: 7px;
}
.close--button div:nth-child(2) {
  opacity: 0;
}
.close--button div:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 7px;
}
@media (min-width: 769px) {
  .nav__logo {
    display: flex;
    align-items: center;
    padding: 11px;
    width: 220px;
    border-right: $border-size;
  }
  .nav ul {
    width: calc(100% - 220px);
  }
  .nav li {
    width: 25%;
    border-right: $border-size;
  }
  .nav li:hover {
    background-color: #1e2d3d;
  }
  .nav li:last-child {
    margin-left: auto;
    border-right: none;
  }
  .nav li a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav li a.active {
    border-bottom: 3px solid $accent-orange;
  }
}
@media (max-width: 768px) {
  .nav {
    padding: 1.125rem;
    justify-content: space-between;
    align-items: center;
  }
  .nav ul {
    height: calc(100% - 57px);
    width: 100%;
    background-color: $primary-light;
  }
  .nav li {
    padding: 0;
  }
  .nav__links {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 56px;
    z-index: 1;
  }
  .nav__links li {
    width: 100%;
    border-bottom: $border-size;
    color: $secondary-write;
    font-weight: 300;
  }
  .nav__links a {
    display: block;
    margin: 1.125rem;
  }
  .nav__button {
    display: flex;
  }
  ul li .active::after {
    content: " <";
  }
}
