@use "../abstracts/variables" as *;
.stack {
  padding-left: 28px;
  margin: 5px 0px;
}
.stack__checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.stack__checkmark {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: -18px;
  border: 1.5px solid $secondary-grey;
  border-radius: 2px;
}
.stack__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.stack__checkbox:checked ~ .stack__checkmark:after {
  display: block;
}
.stack__checkmark::after {
  top: 0.5px;
  left: 5.5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.stack__checkbox:checked + .stack__checkmark {
  background: $secondary-grey;
}
.stack__icon {
  color: $secondary-grey;
  width: 16px;
  height: 16px;
  margin: 0 10px;
}
@media (max-width: 768px) {
  .stack {
    padding-left: 32px;
  }
}
