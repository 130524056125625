@use "./abstracts/variables" as *;
@use "./core/reset";
@use "./core/typography";
// Components
@use "./components/navigation";
@use "./components/accordion";
@use "./components/file";
@use "./components/snippet";
@use "./components/project";
@use "./components/stack";
@use "./components/formconfirmation";
@use "./components/contactform";
@use "./components/link";
// Pages
@use "./pages/home";
@use "./pages/aboutme";
@use "./pages/projects";
@use "./pages/contactme";
html,
body,
#root {
  height: 100%;
  font-size: 1rem;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: $border-size;
}
::-webkit-scrollbar-track {
  background-color: $secondary-grey;
}
::-webkit-scrollbar-thumb {
  background: $accent-orange;
}
::-webkit-scrollbar-thumb:hover {
  background: $accent-orange;
}

// Animations
.text--reveal {
  font-kerning: none;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

@media (min-width: 769px) {
  body {
    background-color: $primary-black;
    padding: 31px;
  }
  .wrapper {
    width: 100%;
    background-color: $primary-light;
    height: 100%;
    border: $border-size;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  body {
    background-color: $primary-light;
    overflow-x: hidden;
  }
  .side--padding {
    padding: 0 27px;
  }
  .top--padding {
    padding: 21px 0;
  }
}
