@use "../abstracts/variables" as *;
.projects {
  display: flex;
}
.construction {
  height: calc(90vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.construction i {
  color: $accent-orange;
  margin-bottom: 16px;
}
.construction__header {
  color: $secondary-blue;
}

@media (min-width: 769px) {
  .projects {
    width: 100%;
    height: 100%;
  }
  .projects__tab {
    height: 44px;
    width: 100%;
    border-bottom: $border-size;
  }
  .projects__wrapper {
    width: calc(100% - 220px);
  }
  .projects__items {
    overflow-y: auto;
    display: grid;
    column-gap: 40px;
    row-gap: 20px;
    justify-content: center;
    grid-template-columns: auto auto;
    height: calc(100% - 100px);
    padding: 16px 0px;
  }
  .construction i {
    font-size: 36px;
  }
  .construction__header {
    text-align: center;
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .construction i {
    font-size: 26px;
  }
  .construction__header {
    text-align: center;
    font-size: 20px;
  }
  .projects {
    flex-direction: column;
  }
  .projects__wrapper {
    display: flex;
    flex-direction: column;
  }
}
