@use "../abstracts/variables" as *;
.about i {
  pointer-events: none;
}
.accordion__item i {
  margin-right: 5px;
  pointer-events: none;
}
.accordion__button {
  display: flex;
  align-items: center;
}
.accordion__label {
  pointer-events: none;
  color: $secondary-write;
}
.folder--closed {
  pointer-events: none;
  color: $secondary-grey;
}
.folder--green {
  color: $accent-green;
}
.folder--peach {
  color: $accent-peach;
}
.folder--purple {
  color: $secondary-purple;
}
.folder--opened {
  color: $secondary-write;
}
@media (min-width: 769px) {
  .accordion {
    width: 220px;
    height: 100%;
    border-right: $border-size;
  }
  .accordion__button {
    padding: 11px;
    width: 100%;
    border-bottom: $border-size;
    border-left: none;
  }
  .folder__item .accordion__button {
    border: none;
  }
}
@media (max-width: 768px) {
  .about__title {
    padding: 21px 0;
  }
  .accordion__item {
    margin-bottom: 3px;
  }
  .accordion__button {
    width: calc(100% + (27px * 2));
    height: 31px;
    margin-left: -27px;
    padding: 0 27px 0 24px;
    background-color: $lines;
  }
  .accordion__item.folder__item button {
    background-color: $primary-light;
  }
}
