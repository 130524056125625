@use "../abstracts/variables" as *;
.file__text {
  color: $accent-orange;
}
@media (min-width: 769px) {
  .about__me {
    display: flex;
    height: 100%;
  }
  .file__content {
    width: 50%;
    border-right: $border-size;
    height: 100%;
  }
  .file__section {
    border-bottom: $border-size;
    padding: 11px;
    color: $secondary-grey;
  }
  .file__text {
    padding: 11px;
  }
  .content {
    width: calc(100% - 220px);
    display: flex;
  }
}
@media (max-width: 768px) {
  .file__content {
    padding: 38px 0;
  }
  .file__path {
    margin-bottom: 16px;
    word-break: break-all;
  }
  .text--grey {
    color: $secondary-grey;
  }
}
