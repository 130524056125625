@use "../abstracts/variables" as *;
.file {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: fit-content;
}
.files {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}
@media (max-width: 768px) {
  .files {
    padding-left: 17px;
  }
}
