@use "../abstracts/variables" as *;
.contact__background {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $primary-black;
  opacity: 0.5;
}
.contact__loader {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 40px !important;
  height: 40px !important;
}
.contact__accordion {
  padding: 5px 13px;
}
.contact__accordion .link {
  color: $secondary-grey;
}
.contact__accordion .link:hover {
  color: $secondary-write;
}
@media (min-width: 769px) {
  .contact__me {
    display: flex;
    height: calc(100% - 55px);
    width: 100%;
  }
}
@media (max-width: 768px) {
  .contact__accordion {
    padding: 5px 17px;
  }
}
