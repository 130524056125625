@use "../abstracts/variables" as *;
@media (max-width: 768px) {
  .link {
    margin-bottom: 9px;
    color: $secondary-grey;
  }
  .link a {
    display: flex;
    align-items: center;
  }
  .link:hover {
    color: $secondary-write;
  }
}
