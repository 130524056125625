// Typography
$font-primary: "Fira Code";
$code-snippet: 0.875rem;
$label: 1rem;
$body-text: 1.125rem;
$subhead: 2rem;
$headline: 3.875rem;
// Colors
$primary-black: #01080e;
$primary-light: #011627;
$primary-blue: #011221;
$secondary-grey: #859ab0;
$secondary-green: #3c9d93;
$secondary-purple: #4d5bce;
$secondary-blue: #5565e8;
$secondary-write: #ffffff;
$accent-orange: #fea55f;
$accent-green: #43d9ad;
$accent-peach: #e99287;
$accent-pink: #c98bdf;
$lines: #1e2d3d;
$link: #1c2b3a;
$gradient-purple: #4d5bce;
$gradient-green: #43d9ad;
$border-size: 1px solid $lines;
:export {
  fontStyle: $font-primary;
  peachColor: $accent-peach;
  accentPink: $accent-pink;
  accentOrange: $accent-orange;
  gradientGreen: $gradient-green;
  borderColor: $border-size;
  backgroundColor: $primary-blue;
}
