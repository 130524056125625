@use "../abstracts/variables" as *;
.snippet_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.snippet__user {
  display: flex;
}
.snippet__image {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  margin-right: 11px;
}
.snippet__image img {
  border-radius: 50%;
}
.snippet__username {
  color: $secondary-blue;
  font-weight: bold;
  font-size: 14px;
}
.snippet__date {
  font-size: 12px;
  color: $secondary-grey;
}
.snippet__button {
  font-size: 14px;
  border-bottom: 1px solid $secondary-write;
}
.snippet__code {
  border-radius: 0 !important;
}
.snippet__explanation {
  display: flex;
  flex-direction: column;
}
.snippet__details {
  color: $secondary-grey;
  font-size: 14px;
  border: $border-size;
  padding: 11px;
  margin: 17px 11px;
}
.snippet__loader {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
}
@media (min-width: 769px) {
  .snippets__wrapper {
    width: 50%;
    height: calc(100% - 45px);
  }
  .snippets {
    height: calc(100% - 45px);
    overflow-y: auto;
  }
  .snippet {
    padding: 11px;
  }
  .snippet__showcase {
    border-bottom: 1px solid #1e2d3d;
    padding: 11px;
    color: #859ab0;
  }
}
@media (max-width: 768px) {
  .snippets {
    padding-bottom: 17px;
  }
  .snippet {
    margin-bottom: 17px;
  }
  .snippet__showcase {
    margin-bottom: 30px;
    color: $secondary-grey;
  }
  .snippet__details {
    color: $secondary-grey;
    font-size: 14px;
    border: $border-size;
    padding: 11px;
    margin: 17px 0px;
  }
}
