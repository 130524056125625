@use "../abstracts/variables" as *;
.contact__input {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.contact__input label {
  color: $secondary-grey;
}
.contact__input input {
  padding: 0 15px;
  height: 41px;
}
.contact__input textarea {
  height: 110px;
  resize: none;
  padding: 10px 15px;
}
.contact__input textarea,
.contact__input input {
  background-color: $primary-blue;
  border: $border-size;
  color: $secondary-grey;
  font-size: 16px;
  font-family: $font-primary;
}
.contact__input label {
  margin-bottom: 10px;
}
.contact__button {
  margin-top: 16px;
  height: 37px;
  border-radius: 8px;
  text-align: center;
  background-color: $secondary-purple;
  padding: 10px 14px;
  line-height: 0px;
}
.contact__error {
  display: none;
  font-size: 14px;
  color: $secondary-purple;
  margin-top: 5px;
}
@media (min-width: 769px) {
  .contact__wrapper {
    width: calc(100% - 220px);
    display: flex;
  }
  .contact__form {
    width: 50%;
    height: 100%;
    border-right: $border-size;
    margin: 0;
    padding: 11px 22px;
  }
  .contact__snippet--wrapper {
    padding: 42px 22px;
    width: 50%;
  }
  .contact__snippet {
    width: 100%;
    height: 90%;
    border-radius: 0px !important;
    margin: 0px !important;
  }
}
